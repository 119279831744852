import React, { useEffect } from "react";
import Nav from "../common/Nav";
import Footer from "../common/Footer";
import ScrollTop from "../common/ScrollTop";

const Layout = ({ children }) => {
  useEffect(()=>{
    console.clear();
    setTimeout(() => {
      console.clear();
    }, 1000);
   },[])

  return (
    <div>
      <Nav />
      {children}
      <Footer />
      <ScrollTop />
    </div>
  );
};

export default Layout;
