import React from "react";
import Layout from "../../components/layout";
import Gaming from "../../components/views/industries/gaming";

const GamingPage = () => {
  return (
    <Layout>
      <Gaming />
    </Layout>
  );
};

export default GamingPage;
