import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import "../../styles/callToAction.css";
const CallToAction = ({tag}) => {
  const data = useStaticQuery(graphql`
    {
      allStrapiCallToActions {
        nodes {
          sectionsList {
            buttonName
            title
          }
        }
      }
    }
  `);
  const { sectionsList } = data.allStrapiCallToActions.nodes[0];
  const { title, buttonName } = sectionsList[0];
  return (
    <section className="section built-tech mt-6 my-0">
      <div className="container py-2">
        <div className="shapes-container"></div>
        <div className="row">
          <div className="col-lg-6 col-md-12 col-lg-12">
            <div className="card shadow border-0 cta-card">
              <div className="card-body cta-flex-card ">
                <div className="row">
                  <div className="col-md-12 cta-flex-card flex-column">
                    <div className="section-heading text-center mb-0">
                      <h2 className="text-white">{title}</h2>
                    </div>
                    <div className="pt-4">
                      <a className={`tagmanager-contactus-${tag} btn btn-primary-cta cta-btn btn-rounded bold px-4 py-2 text-t cta-btn`} href="/contactus/" title={`${buttonName} page link`}>{buttonName}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CallToAction;
