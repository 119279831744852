import React from "react";

const HeaderBackground = (props) => {
  const { backgroundClass } = props;
  return (
    <>
      <div
      className={`position-absolute banner-bg ${backgroundClass}`}
 ></div>
      <div
        className="position-absolute black-shade-bg"
      ></div>
    </>
  );
};

export default HeaderBackground;
