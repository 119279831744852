import React from "react";
import ReactMarkdown from "react-markdown";
import AboutContainer from "./AboutContainer";

const AboutSection = ({ aboutData }) => {
  const { aboutTitle, aboutDescription, sectionsList } = aboutData;
  return (
    <>
      <section className="section border-top max-vw-100 overflow-hidden">
        <div className="row">
          <div className="container bring-to-front pb-0">
            <div className="section-heading text-center">
              <h2 className="bold">{aboutTitle}</h2>
              <ReactMarkdown
                source={aboutDescription}
                className="lead text-secondary"
              />
            </div>
          </div>
        </div>
        {sectionsList.map((sectionData, index) => (
          <div key={index} className="row section-wrap">
            <div className={`container bring-to-front feature${index === 0 ? " pt-0" : ""}`}>
              <AboutContainer
                key={index}
                sectionData={sectionData}
                index={index}
              />
            </div>
          </div>
        ))}
      </section>
    </>
  );
};

export default AboutSection;
