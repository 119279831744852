import React from "react";
import ReactMarkdown from "react-markdown";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const AboutContainer = ({ sectionData, index }) => {
  const { sectionTitle, description, image } = sectionData;
  const createdImage = getImage(image);
  return index % 2 === 0 ? (
    <div className="row gap-y align-items-center">
      <div className="col-lg-6 mr-lg-auto">
        <GatsbyImage image={createdImage} alt={`${sectionTitle} image`} />
      </div>
      <div className="col-lg-6 p-4">
        <div className="section-heading">
          <h2 className="bold font-md heading-line">{sectionTitle}</h2>
          <ReactMarkdown source={description} className="text-secondary" />
        </div>
      </div>
    </div>
  ) : (
    <div className="row gap-y align-items-center">
      <div className="col-lg-6 p-4">
        <div className="section-heading">
          <h2 className="bold font-md heading-line">{sectionTitle}</h2>
          <ReactMarkdown source={description} className="text-secondary" />
        </div>
      </div>
      <div className="col-lg-6 ml-lg-auto">
        <GatsbyImage image={createdImage} alt={`${sectionTitle} image`} />
      </div>
    </div>
  );
};

export default AboutContainer;
