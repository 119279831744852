import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { isoCertifiedText } from "../../config";

const NavDesktop = React.lazy(() => import("./NavDesktop"));
const NavMobile = React.lazy(() => import("./NavMobile"));
const isSSR = typeof window === "undefined";
const Nav = () => {
  const data = useStaticQuery(graphql`
    {
      allStrapiNavLinks(sort: {fields: slug, order: ASC}) {
        nodes {
          isLink
          linksList {
            link
            title
            type
            externalLink
          }
          title
        }
      }
      strapiGrootanSiteLogos {
        image {
          childImageSharp {
            gatsbyImageData(quality: 20, placeholder: NONE)
          }
        }
      }
    }
  `);
 
  const { nodes: mainLinks } = data.allStrapiNavLinks;
  const { image } = data.strapiGrootanSiteLogos;
  const createdImage = getImage(image);
  const [currentTab, setCurrentTab] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [currentDropdown, setCurrentDropdown] = useState("");

  const showMenu = () => {
    setShowDropdown(true);
  };
  const hideMenu = () => {
    setShowDropdown(false);
  };
  
  return (
    <nav
      className="st-nav navbar main-nav navigation fixed-top dark-link"
      id="main-nav"
    >
      <div className="container px-0">
        <ul className="st-nav-menu nav navbar-nav">
          <li className="st-nav-section nav-item">
            <Link
              to="/"
              className="tagmanager-logo-home-nav navbar-brand"
              title={`home page link`}
              noPrefetch
            >
              <GatsbyImage image={createdImage} alt="grootan logo" />
            </Link>
            <div className="iso-certified-text">{isoCertifiedText}</div>
          </li>

          <li className="st-nav-section st-nav-primary nav-item">
            {!isSSR && (<React.Suspense fallback={<div />}>
              {mainLinks.map((linkData, index) => (
                <NavDesktop
                  key={index}
                  linkData={linkData}
                  currentTab={currentTab}
                  setCurrentTab={setCurrentTab}
                />
              ))}
            </React.Suspense>)}
            <div className="d-flex align-items-center">
              {!isSSR && (<React.Suspense fallback={<div />}>
                <Link
                  to="/contactus/"
                  className="tagmanager-contactus-nav btn btn-primary  btn-rounded bold px-4 py-2"
                  title="contactus page link"
                  noPrefetch
                >
                  Let's Talk
                </Link>
              </React.Suspense>)}
            </div>
          </li>
          {!isSSR && (<React.Suspense fallback={<div />}>
          <li
            className={`st-nav-section st-nav-mobile nav-item ${
              showDropdown ? "st-popup-active" : ""
            }`}
          >
            <button
              className="st-root-link navbar-toggler"
              type="button"
              onClick={showMenu}
            >
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>
            </button>
            {showDropdown ? (
              <div className="st-popup">
                <div className="st-popup-container">
                  <span className="st-popup-close-button" onClick={hideMenu}>
                    Close
                  </span>
                  <div className="st-dropdown-content-group">
                    {mainLinks.map((linkData, index) => (
                      <NavMobile
                        key={index}
                        linkData={linkData}
                        currentDropdown={currentDropdown}
                        setCurrentDropdown={setCurrentDropdown}
                      />
                    ))}
                    <li className="tagmanager-contactus-nav dropdown-nav-container mobile last-menu"><Link to="/contactus/">Let's Talk</Link></li>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </li>
          </React.Suspense>)}
        </ul>
      </div>
    </nav>
  );
};

export default Nav;
